/**
 * Renders pagination controls
 */

import React              from 'react';
import styled             from 'styled-components';
import { Button  }        from 'semantic-ui-react';

import Icon               from './Icon';

const PaginationContainer = styled.div`
margin-top: 0.5em;

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
`;

const PageNumberDisplay = styled.div`
display: inline-block;
background-color: #E0E1E2;
border-radius: ${props => props.theme.input_radius};
border-color: ${props => props.theme.color.dark_grey};
height: 2.5em;
text-align: center;
padding: 0.5em 1em;
margin-right: 0.3em;
`;

export default function Pagination(props){
  const {
    /**
     * Controlled component callback and value
     */
    setPage, page,

    /**
     * Boolean flag - if true then text will be rendered informing the user of which
     * items are currently being shown (eg, 10-19 of 200)
     */
    show_position,

    /**
     * Number of items displayed per page
     */
    items_per_page,

    /**
     * Total number of items displayed on pages
     */
    item_count,

    /**
     * Number of pages shown - defaults to item_count / items_per_page
     * but can be explicity defined if item_count is not set
     */
    page_count = items_per_page && item_count ? Math.ceil(props.item_count / props.items_per_page) : 1,
  } = props;

  let first_shown = (page-1) * items_per_page;

  return (
    <div style={{textAlign: 'center'}}>
      <PaginationContainer>
        <Button icon onClick={e => setPage(1)}>
          <Icon name="angle double left"/>
        </Button>
        <Button icon onClick={e => setPage(x => Math.max(1, x - 1))}>
          <Icon name="angle left"/>
        </Button>

        <PageNumberDisplay>
          { page} / {page_count}
        </PageNumberDisplay>

        <Button icon onClick={e => setPage(x => Math.min(page_count, x + 1))}>
          <Icon name="angle right"/>
        </Button>
        <Button icon onClick={e => setPage(page_count)}>
          <Icon name="angle double right"/>
        </Button>
      </PaginationContainer>

      { show_position ? (
        <span>Showing { first_shown+1 } - { Math.min(first_shown + items_per_page, item_count) } of { item_count }</span>
      ) : null
      }
    </div>
  );
}

export { Pagination };
