/**
 * Renders a container clearly seperated from other content
 */

import styled from 'styled-components';

export const Card = styled.div`
padding: ${props => props.no_padding ? '0px' : '1em'};
margin: ${props => props.no_margin ? '0px' : '1em'};
text-align: ${props => props.text_align ? props.text_align : 'left'};

box-shadow: ${props => props.plain ? 'none' : `0px 0px ${props.theme.shadow_spread} ${props.theme.shadow_spread} ${props.theme.color.faint}`};

`;

export default Card;
