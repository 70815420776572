/**
 * Data store for Entity knowledge graph data
 *
 * :TODO: This should have an associated service worker to automatically download the data and
 * cache on the client side
 */

import React from 'react';

export const Context = React.createContext({});

/**
 * HOC which provides the context to all subcomponents
 */
export function HOC(props){
  const [ store, setStore ] = React.useState({});

  // Since react batches state updates, it is possible for multiple useEntityGraph hooks
  // on a single page to both request a fetch of the same entity before the setStore call
  // is processed. To prevent this, we store fetches start since the last setStore in this
  // object. It will be recreated (and therefore cleared) each time this component re-renders,
  // but by that point we are guarentied the store will have been updated, so we can use that
  // to prevent re-fetching the same entity more than once
  let cur_fetches = {};

  async function getEntity(id){
    if(store[id]      ){ return store[id]; }
    if(cur_fetches[id]){ return { loading: true, id: id }; }
    setStore(x => ({ ...x, [id]: { loading: true } }));

    let promise = fetch(`/api/know/entity/${id}`);
    cur_fetches[id] = promise;
    let res = await promise;

    if(res.status >= 400){
      console.error(`Failed to fetch data for entity: ${id}: ${res.status_text}`);
      let new_val = { error: "Failed to fetch data for entity" };
      setStore(x => ({ ...x, [id]: new_val }));
      return new_val;
    }

    let data = await res.json();

    setStore(x => ({ ...x, [id]: data }));

    return data;
  }

  const provided = {
    store,
    getEntity,
  };

  return (
    <Context.Provider value={provided}>
      { props.children }
    </Context.Provider>
  );
}

/**
 * Hook which exposes entity data to components
 */
export function useEntityKnowledge(entity_ids){
  let { store, getEntity } = React.useContext(Context);

  let result = {};

  let any_loading = false;
  let any_errors  = false;
  for(let id of entity_ids){
    getEntity(id);
    if(store[id]){
      result[id] = store[id];
      any_loading |= store[id].loading;
      any_errors  |= store[id].error !== undefined;
    } else {
      store[id] = { loading: true };
      any_loading = true;
    }
  }

  return { loading: any_loading, error: any_errors, entities: result };
}

/**
 * Generates a color for an entity given its id.
 *
 * Will be returned as array of 3 components for hue, saturation and lightness
 * in the ranges [ 0-360, 0-100, 0-100 ]
 *
 * This color space is used to ensure the generated colors are light enough
 * that black text shows up on them (if we one day do a dark theme - we can also
 * keep the same hue but just adjust the lightness in order to make white text
 * visible)
 */
export function generateHslColorFromId(id){
  let hash = 0;
  for(let i = 0; i < id.length; ++i){
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);

  let hue = (hash % 360 ^ ((hash >> 9) % 360)) % 360;
  hash = hash >> 9; // discard bottom 9 bits (used to generate hue)

  let sat = 50 + (50 * ((hash & 7) / 7));
  hash = hash >> 3;

  let light = 60 + (25 * ((hash & 7) / 7));

  return [ hue, sat, light ];
}

/**
 * Generates a string which can be put into CSS representing the color
 * of an entity
 */
export function generateCssColorFromId(id, opacity = 1.0){
  let [ hue, sat, light ] = generateHslColorFromId(id);
  return `hsl(${hue}, ${sat}%, ${light}%, ${opacity})`;
}

/**
 * Generates an RGB color string such as #FFFFFF for the
 * given entity id
 */
export function generateRgbColorFromId(id){
  let [h,s,l] = generateHslColorFromId(id);
  let [r,g,b] = _hslToRgb(h / 360, s / 100, l / 100);
  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
}

function _hslToRgb(h,s,l){
  var r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return [ Math.round(r * 255), Math.round(g * 255), Math.round(b * 255) ];
}
