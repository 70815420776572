import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
color: ${props => props.dull ? props.theme.color.dark_grey : props.theme.color.pink};
margin-bottom: ${props => props.no_margin ? '0px' : '1.0em'};
text-align: ${props => props.center ? 'center' : 'left'};
`;

const Subtitle = styled.div`
color: ${props => props.theme.color.dull};
text-transform: uppercase;
font-size: 0.9em;
`;

export default function Header({
  dull,
  className,
  style,
  id,
  size,
  children,
  subheader,
  no_margin,
  center,
}){

  size = size || 2;

  let elm_subheader = null;
  if(subheader !== undefined){
    let sub_size = size + 3;
    if(sub_size > 5){ sub_size = 5; }
    elm_subheader = (
      <Subtitle>
        { React.createElement(`h${sub_size}`, { className: 'dull' }, subheader) }
      </Subtitle>
    );
  }

  return (
    <HeaderContainer
      id={id}
      className={className} style={style}
      no_margin={no_margin} dull={dull} center={center}
    >
      { React.createElement(`h${size}`, null, children) }
      { elm_subheader }
    </HeaderContainer>
  );

}
export { Header };
