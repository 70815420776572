/**
 * Renders a list of breadcrumb navigation components
 */

import React    from 'react';
import styled   from 'styled-components';
import { Icon } from 'semantic-ui-react';


const Seperator = styled.span`
color: ${props => props.theme.color.dull};
user-select: none;
display: inline-block;
padding: 0em 0.5em !important;
`;

export default function Breadcrumbs(props){
  const { items } = props;

  let elms = [];
  for(let i = 0; i < items.length-1; ++i){
    elms.push(items[i]);
    elms.push(<Seperator key={`bcrumb-sep-${i}`}><Icon fitted name='chevron right'/></Seperator>);
  }
  elms.push(items[items.length-1]);

  return (
    <div>
      { elms }
    </div>
  );
}

export { Breadcrumbs };
