/**
 * Removable tag component
 */

import React  from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const Container = styled.span`
display: inline-block;
background-color: ${props => props.theme.color.faint};
border-radius: ${props => props.theme.input_radius};
box-shadow: 1px 1px 1px ${props => props.theme.color.dull};
margin: 0px 0.2em;
cursor: ${props => props.interactive ? 'pointer' : 'default'};
transition: box-shadow ${props => props.theme.transition.quick};

body.has_hover &:hover {
  box-shadow: 1px 1px 1px ${props => props.theme.color.primary};
}

body.has_hover &:hover:active {
  box-shadow: inset 1px 1px 1px ${props => props.theme.color.primary};;
}
`;

const TextContent = styled.span`
display: inline-block;
margin: 0.1em 0.5em;
`;

const ActionButton = styled.span`
cursor: pointer;
display: inline-block;
background-color: ${props => props.theme.color.faint};
padding: 0.1em 0.3em;
height: 100%;
border-top-right-radius: ${props => props.theme.input_radius};
border-bottom-right-radius: ${props => props.theme.input_radius};

body.has_hover &:hover {
  color: ${props => props.theme.color.red};
}
`;

export default function Tag(props){
  const {
    onDelete,
    onClick,
  } = props;

  function _onDelete(e){
    // Prevent triggering the onClick as well
    e.stopPropagation();
    onDelete(e);
  }

  return (
    <Container interactive={onClick !== undefined} onClick={onClick}>
      <TextContent>
        { props.children }
      </TextContent>
      { onDelete !== undefined ?
        <ActionButton onClick={e => _onDelete(e)}><Icon fitted name="delete"/></ActionButton> :
        null
      }
    </Container>
  );
}

export { Tag };
