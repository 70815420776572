/**
 * Renders a vertically collapsable component, good for making accordions or similar
 */

import React  from 'react';
import styled from 'styled-components';

const CollapseContainer = styled.div`
overflow-y: hidden;
max-height: ${props => props.expanded ? props.desired_height : '0px'};
transition: max-height ${props => props.transition_speed || props.theme.transition.quick} ease;
`;

export function Collapse({ expanded, children, style, className }){

  const [height, setHeight] = React.useState('0px');
  let elm_inner = React.useRef();
  React.useEffect(() => {
    setHeight(`${elm_inner.current.clientHeight}px`);
  });

  return (
    <CollapseContainer
      style={style}
      className={className}
      expanded={expanded}
      inner={elm_inner.current}
      desired_height={height}
    >
      <div ref={elm_inner}>
        { children }
      </div>
    </CollapseContainer>
  );
}

export default Collapse;
