/**
 * Data store for Knowledge data by chapter
 *
 * :TODO: This should have an associated service worker to automatically download the data and
 * cache on the client side
 */

import React     from 'react';
import AwokenRef from 'awoken-bible-reference';

export const Context = React.createContext({});

/**
 * HOC which provides the context to all subcomponents
 */
export function HOC(props){
  const [ store, setStore ] = React.useState({});

  // Since react batches state updates, it is possible for multiple useChapterKnowledge hooks
  // on a single page to both request a fetch of the same entity before the setStore call
  // is processed. To prevent this, we store fetches started since the last setStore in this
  // object. It will be recreated (and therefore cleared) each time this component re-renders,
  // but by that point we are guarentied the store will have been updated, so we can use that
  // to prevent re-fetching the same entity more than once
  let cur_fetches = {};

  async function getChapterKnowledge(book, chapter){
    let id = _generateStoreKey(book, chapter);

    if(store[id]      ){ return store[id]; }
    if(cur_fetches[id]){ return { loading: true }; }
    setStore(x => ({ ...x, [id]: { loading: true } }));

    let promise = fetch(`/api/know/byvref/${id}`);
    cur_fetches[id] = promise;
    let res = await promise;

    if(res.status >= 400){
      console.error(`Failed to fetch knowledge data by vref: ${id}: ${res.status_text}`);
      let new_val = { error: "Failed to fetch data" };
      setStore(x => ({ ...x, [id]: new_val }));
      return new_val;
    }

    // Perform some one time preprocessing on the data
    // (to inflate compressed url encoded vrefs in API response to full BibleRef[] instances)
    let data = await res.json();
    data.events = data.events.map(_expandEventTreeVrefs);
    for(let a of data.authorship){
      a.vref = AwokenRef.parseOrThrow(a.vref);
    }

    setStore(x => ({ ...x, [id]: data }));
    return data;
  }

  const provided = {
    store,
    getChapterKnowledge,
  };

  return (
    <Context.Provider value={provided}>
      { props.children }
    </Context.Provider>
  );
}

/**
 * Hook which exposes chapter data to components
 */
export function useChapterKnowledge(book, chapter){
  let { getChapterKnowledge } = React.useContext(Context);
  return getChapterKnowledge(book, chapter);
}

/**
 * Gets vref knowledge for arbitrary verserefs, split into chunks
 * on book boundaries (blocks are equal to useBibleText)
 */
export function useVrefKnowledge(verseref = []){
  let { store, getChapterKnowledge } = React.useContext(Context);

  let chapters = React.useMemo(() => {
    if(verseref === null){ return []; }
    return AwokenRef.splitByChapter(verseref).map(v => ({
      book    : v.is_range ? v.start.book    : v.book,
      chapter : v.is_range ? v.start.chapter : v.chapter,
      ref     : v,
    }));
  }, [ verseref ]);

  // Ensure we have the knowledge data available (we will automatically
  // prevent duplicate API calls)
  for(let c of chapters){
    getChapterKnowledge(c.book, c.chapter);
  }

  return React.useMemo(() => {
    if(!verseref){
      return { blocks: [], entities: {}, loading: false };
    };

    let any_loading = false;

    let blocks   = [];
    let entities = {};

    for(let v of chapters){
      let data = store[_generateStoreKey(v.book, v.chapter)];

      if(!data || data.loading){
        any_loading = true;
        continue;
      }

      if(data.error){ continue; }

      // We need to process the data to extract just relevant details
      blocks.push(_extractSubsection(v.ref, data));

      entities = { ...entities, ...data.entities };
    }

    return {
      blocks, entities, loading: any_loading
    };
  }, [ chapters, store, verseref ]);
}


// Given a block of data returned from the API (IE: for a whole chapter), and a verseref, filters
// the data down to include just that relevant to specified verseref
function _extractSubsection(ref, input){
  let result = {
    by_verse   : {},
    entities   : {},
    events     : [],
    authorship : [],
  };

  // Handle by_verse and entities
  let verses = AwokenRef.splitByVerse(ref).map(x => AwokenRef.format(x, { url: true }));
  for(let v of verses){
    result.by_verse[v] = input.by_verse[v];
    for(let eid of Object.keys(input.by_verse[v].entities)){
      result.entities[eid] = input.entities[eid];
    }
  }

  // Handle Events
  result.events = input.events.map(x => _filterEventTree(x, ref));

  // Handle authorship
  for(let a of input.authorship){
    if(AwokenRef.getIntersection(a.vref, ref).length){
      result.authorship.push(a);
    }
  }

  return result;
}

// Takes the url string encoded verserefs in event tree and expands them
// into full BibleRef[] instances
function _expandEventTreeVrefs(node){
  if(node.vref && node.vref.length){
    node.vref = AwokenRef.parseOrThrow(node.vref);
  }

  for(let c of node.children){
    _expandEventTreeVrefs(c);
  }

  return node;
}

// Takes a event tree and filters to only the subset within a specified vref
function _filterEventTree(node, filter_ref){
  let children = [];
  for(let c of node.children){
    let x = _filterEventTree(c, filter_ref);
    if(x){ children.push(x); }
  }

  // If children are valid, or we have our own valid vref, then return this node
  if(children.length || (node.vref && AwokenRef.getIntersection(node.vref, filter_ref).length)){
    return { ...node, children };
  } else {
    return null;
  }
}

function _generateStoreKey(book, chapter){
  return `${book.toLowerCase()}${chapter}`;
}
