/**
 * Renders an ActionText styled to appear as a tab button with additional styling
 * to represent the currently selected item
 */

import styled     from 'styled-components';
import ActionText from './ActionText';

const TabButton = styled(ActionText)`
display: inline-block;
line-height: ${props => props.height || '3em'};
box-sizing: border-box;
padding: 0px 1em;
margin: 0px;
font-size: 1.3em;
border-left  : 1px solid ${props => props.theme.color.faint};
border-right : 1px solid ${props => props.theme.color.faint};


box-shadow: ${props => props.active ? `inset 0px 6px 2px -3px ${props.theme.color.focused}` : 'none'}
`;

/**
 * Horizontal container of tabs
 */
const TabButtonBar = styled.div`
border-bottom : 3px solid ${props => props.theme.color.faint};

margin-bottom: 1em;

& ${TabButton} {
  border-top   : 1px solid ${props => props.theme.color.faint};
}
`;

export { TabButton, TabButtonBar };
export default TabButton;
