/**
 * Renders main app content with header, page, and footer
 */

import React               from 'react';
import styled              from 'styled-components';
import { Route, Switch  }  from 'react-router-dom';
import { Loader }          from 'semantic-ui-react';

import TopNav              from './ui/common/top-nav';
import CookieConsent       from './ui/common/CookieConsent';
import Footer              from './ui/common/footer';

const LayoutHome          = React.lazy(() => import('./ui/home'               ));
const LayoutReader        = React.lazy(() => import('./ui/reader'             ));
const LayoutExplore       = React.lazy(() => import('./ui/explore'            ));
const LayoutAbout         = React.lazy(() => import('./ui/pages/about'        ));
const LayoutContact       = React.lazy(() => import('./ui/pages/contact'      ));
const LayoutFaq           = React.lazy(() => import('./ui/pages/faq'          ));
const LayoutDonate        = React.lazy(() => import('./ui/pages/donate'       ));
const LayoutDonateThanks  = React.lazy(() => import('./ui/pages/donate-thanks'));
const LayoutTerms         = React.lazy(() => import('./ui/pages/terms'        ));
const LayoutPrivacy       = React.lazy(() => import('./ui/pages/privacy'      ));
const LayoutAttributions  = React.lazy(() => import('./ui/pages/attributions' ));
const Layout404           = React.lazy(() => import('./ui/pages/404'          ));

// Container for page content + footer, set up with minheight and flex box to
// ensure the footer stays at bottom of even short pages
const Container = styled.div`
min-height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
`;

export default function AppContent(){
  return (
    <Container>
      <TopNav/>

      <React.Suspense fallback={<Loader active size='massive'/>}>
        <Switch>
          <Route       path='/read/:vref?'     > <LayoutReader/>  </Route>
          <Route       path='/explore'         > <LayoutExplore/> </Route>
          <Route exact path="/"                > <LayoutHome/>    </Route>
          <Route exact path="/about"           > <LayoutAbout/>   </Route>
          <Route exact path="/contact"         > <LayoutContact/> </Route>
          <Route exact path="/faq"             > <LayoutFaq/>     </Route>
          <Route exact path="/donate/thank-you"> <LayoutDonateThanks/>  </Route>
          <Route exact path="/donate"          > <LayoutDonate/>  </Route>
          <Route exact path="/terms"           > <LayoutTerms/>   </Route>
          <Route exact path="/privacy"         > <LayoutPrivacy/> </Route>
          <Route exact path="/attributions"    > <LayoutAttributions/> </Route>
          <Route       path='/'                > <Layout404/> </Route>
        </Switch>
      </React.Suspense>

      <Footer/>
      <CookieConsent/>
    </Container>
  );
}
