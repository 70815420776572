/**
 * Contains implementation for the "omni-search" box which allows users to quickly
 * jump to different parts of the site
 */

import React                           from 'react';
import styled                          from 'styled-components';
import { Input, Icon }                 from 'semantic-ui-react';
import { useHistory }                  from 'react-router-dom';

import BibleRef                        from 'awoken-bible-reference';
import * as Aui from '../design';

// Maps Knowledge graph entity labels to data about displaying them in list
// - icon  -> icon to show in header
// - name  -> user friendly section header
// - order -> sort order for sections
const ENTITY_TYPES = {
  'Character'   : { icon: 'user',                 name: 'Character',         order: 0 },
  'Place'       : { icon: 'map marker alternate', name: 'Place',             order: 5 },
  'PeopleGroup' : { icon: 'users',                name: 'People',            order: 6 },
  'Concept'     : { icon: 'box',                  name: 'Themes, Concepts and Objects', order: 7 },
};

const OuterContainer = styled.div`
position: relative;
flex-grow: 1;
overflow-y: visible;
input {
	border-radius: 2em !important;
}
margin-bottom: 0.3em;
`;

const PopupContainer = styled.div`
position: absolute;
top: 3.2em;
z-index: 1000;
width: 100%;

display: ${props => props.shown ? 'block' : 'none'};
border-radius: ${props => props.theme.input_radius };
border: 1px solid ${props => props.theme.color.focused};
background-color: ${props => props.theme.color.background };
`;

const SearchResultContainer = styled.div`
display: relative;
flex-direction: row;
justify-content: start;
padding: 0.1em 0.5em;

cursor: pointer;
line-height: 1.5em;

background-color : ${props => props.active ? props.theme.color.secondary      : 'none'};
color            : ${props => props.active ? props.theme.color.secondary_text : 'inherit'};

transition: background-color ${props => props.theme.transition.quick},
            color ${props => props.theme.transition.quick};

body.has_hover &:hover {
	background-color: ${props => props.theme.color.secondary};
	color           : ${props => props.theme.color.secondary_text};
}
`;

const SearchResultName = styled.span`
display: inline-block;
font-size: 1.2rem;
line-height: 1.2rem;
`;

const SearchResultDescription = styled.span`
margin-left: 0.5em;
display: inline-block;
font-style: italic;
font-size: 1.0rem;
line-height: 1.2rem;
`;



function SearchResult(props){
  const { title, description, active, onMouseOver, onClick } = props;

  return (
    <SearchResultContainer active={active} onMouseOver={onMouseOver} onMouseDown={onClick}>
      <SearchResultName>{title}</SearchResultName>
      { description ? (<SearchResultDescription> { description } </SearchResultDescription>) : null }
    </SearchResultContainer>
  );
};

const SearchListGroup = styled.div`
margin: 1em 0em;
`;

const SearchListGroupItems = styled.div`
display: flex;
flex-direction: column;
`;

const SearchResultList = React.forwardRef(function SearchResultList(props, ref) {
  const { results, onSelectionMade } = props;

  const [ active_item, setActiveItem ] = React.useState(null);
  const history = useHistory();

  const [ content_elms, targets ] = React.useMemo(() => {
    let sublists = [];
    let targets  = [];

    let index = 0;
    for(let list of results){

      let elm_items = [];

      for(let item of list.results){
        targets.push(item.target);
        elm_items.push(
          <SearchResult {...item} key={item.target}
                        active={active_item === index}
                        onClick={e => history.push(item.target)}
                        onMouseOver={e => setActiveItem(null)}
          />
        );
        ++index;
      }

      sublists.push(
        <SearchListGroup key={list.name}>
          <Aui.Header center no_margin size={5}>
            <Icon name={list.icon}/>{list.name}
          </Aui.Header>
          <SearchListGroupItems>
            { elm_items }
          </SearchListGroupItems>
        </SearchListGroup>
      );
    }

    return [ sublists, targets, setActiveItem, history ];
  }, [ results, active_item, history ]);
  let max_index = targets.length - 1;

  function onKeyDown(e){
    let handled = true;
    switch(e.key){
    case 'ArrowUp':
      setActiveItem(x => Math.max(0, x === null ? max_index : x-1));
      break;
    case 'ArrowDown':
      setActiveItem(x => Math.min(max_index, x === null ? 0 : x+1));
      break;
    case 'PageUp':
      setActiveItem(x => Math.max(0, x === null ? max_index - 10 : x-10));
      break;
    case 'PageDown':
      setActiveItem(x => Math.min(max_index, x === null ? 9 : x+10));
      break;
    case 'Enter':
      if(active_item !== null || targets.length === 1){
        history.push(targets[active_item || 0]);
        onSelectionMade();
      }
      break;
    default:
      handled = false;
      break;
    }

    if(handled){
      e.preventDefault();
      e.stopPropagation();
    }
  }

  // Expose key handler to parent component
  React.useImperativeHandle(ref, () => ({ onKeyDown }));

  // Reset the active item when list changes
  React.useEffect(() => setActiveItem(null), [ results ]);

  return (
    <div ref={ref} onKeyDown={onKeyDown}>
      { content_elms }
    </div>
  );
});

export default function NavSearch(){
  const [ loading,     setLoading    ] = React.useState(false);
  const [ search_term, setSearchTerm ] = React.useState('');
  const [ api_results, setApiResults ] = React.useState([]);
  const [ focused,     setFocused    ] = React.useState(false);

  const elm_search_results             = React.useRef();
  const elm_root                       = React.useRef();

  async function onSearchTermChange(e){
    let raw_text = e.target.value;
    setSearchTerm(raw_text);
    let search_term = raw_text.toLowerCase().trim();
    if(search_term === ''){
      setApiResults([]);
      setSearchTerm('');
      return;
    }

    setLoading(true);

    let res = await fetch('/api/search/find/' + search_term);
    let json = await res.json();

    let results = [];

    if(json.events.length){
      let event_results = json.events
          .slice(0, 5)
          .map((e) => ({
            title: e.name,
            target: `/read/${e.vref}`,
          }));
      results.push({
        order   : 1,
        icon    : 'clock',
        name    : 'Event',
        results : event_results,
      });
    }

    let results_by_type = {};

    if(json.entities){
      for(let e of json.entities){
        let type = ENTITY_TYPES[e.labels[0]];
        if(!type){ continue; }

        if(!results_by_type[type.name]){
          results_by_type[type.name] = {
            ...type,
            results: [],
          };
        }

        results_by_type[type.name].results.push({
          target: `/explore/${e.id}`,
          title: e.name,
          description: e.tagline,
        });
      }
    }

    for(let sublist of Object.values(results_by_type)){
      results.push(sublist);
    }
    results.sort((a,b) => a.order - b.order);

    setApiResults(results);
    setLoading(false);
  }

  const book_results = React.useMemo(() => {
    let readable = _generateReadResults(search_term.toLowerCase());
    if(readable.length){
      return [{
        icon: 'book',
        name: 'Read',
        results: readable,
      }];
    }
    return [];
  }, [ search_term ]);

  let results = [
    ...book_results,
    ...api_results,
  ];

  function onSelectionMade(){
    document.activeElement.blur();
  }

  function handleKeyDown(e){
    elm_search_results.current.onKeyDown(e);
  }

  return (
    <OuterContainer
      ref={elm_root}
         onFocus = {e => setFocused(true )}
         onBlur  = {e => setFocused(false)}
    >
      <Input fluid icon={'search'} loading={loading}
             value={search_term} onChange={onSearchTermChange}
             onKeyDown={handleKeyDown}
      />
      <PopupContainer shown={focused && results.length}>
        <SearchResultList results={results}
                          ref={elm_search_results}
                          onSelectionMade={onSelectionMade}
        />
      </PopupContainer>
    </OuterContainer>
  );
}

/**
 * Given the current search string generates a list of readable entries
 */
function _generateReadResults(search_str){
  const MAX_RESULTS = 10;

  if(search_str === ''){
    return [];
  }

  let parse_result = BibleRef.parse(search_str);

  if(parse_result.status){
    if(!BibleRef.validate(parse_result.value)){
      // Then it looks valid, but a chapter/verse is out of bounds
      return [];
    }
    let results = BibleRef.splitByChapter(parse_result.value);

    results = results.slice(0,MAX_RESULTS);

    return results.map((x) => (
      { title: BibleRef.format(x, { compact: true }),
        target: `/read/${BibleRef.format(x,{ url: true })}`
      }
    ));
  }

  // If search string is not a valid verseref, lets try doing a text based matching
  // on book names...

  let matching_books = [];

  for(let book_id in BibleRef.versification.book){
    let book = BibleRef.versification.book[book_id];

    let matches = search_str.length <= 3 && book_id.toLowerCase().indexOf(search_str) >= 0;

    if(!matches && book.name.toLowerCase().indexOf(search_str)  >= 0){
      matches = true;
    }

    if(!matches){
      for(let a of book.aliases){
        matches |= a.toLowerCase().indexOf(search_str) >= 0;
      }
    }

    if(matches){
      matching_books.push(book);
    }
  }

  if(matching_books.length > 1){
    return matching_books.sort().splice(0, MAX_RESULTS).map((b) => {
      let vref = BibleRef.makeRange(b.id, 1);
      return { title: BibleRef.format(vref, { compact: true }), target: `/read/${b.id}1` };
    });
  } else if(matching_books.length === 1){
    let results = BibleRef.splitByChapter(BibleRef.makeRange(matching_books[0].id));
    results = results.slice(0,MAX_RESULTS);
    return results.map((x) => (
      { title:  BibleRef.format(x, { compact: true }),
        target: `/read/${BibleRef.format(x, { url: true })}`
      }
    ));
  }

  return [];

}
