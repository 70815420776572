import React            from 'react';
import styled           from 'styled-components';
import * as Aui         from '../design';
import { Button, Icon } from 'semantic-ui-react';

const FooterContainer = styled.footer`
position: sticky;
top: 100%;

width: 100%;
text-align: center;
box-shadow -3px 0px 5px 0px rgba(0,0,0,0.75);

display: grid;
grid-template-columns 1fr 1fr 2fr;
grid-template-areas: "copyright links donate";
padding: 2em 1em;
grid-column-gap: 0.5em;

@media (max-width: 768px) {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "donate donate" "links copyright";
  grid-row-gap: 2em;
  grid-column-gap: 0.5em;
}
`;

const FooterContentContainer = styled.div`
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
height: 100%;

& a {
  display: block;
}

& img {
  display: block;
  margin: 0 auto;
}`;

const CopyText = styled.div`
font-size: 0.8em;
color: #5d5d5d;
`;

export default function Footer(){

  return (
    <FooterContainer>

      <FooterContentContainer style={{ gridArea: 'copyright' }}>
        <img alt='Awoken Bible Logo' src="/img/logo-shadowed.svg" style={{height: '64px', width: '64px'}}/>
        <CopyText>
          Awoken Bible Copyright &copy; Jamie Terry { new Date().getFullYear() }<br/>
          All rights reserved
        </CopyText>
      </FooterContentContainer>

      <FooterContentContainer style={{ gridArea: 'links' }}>
        <Aui.ActionText to="/about">About Us</Aui.ActionText>
        <Aui.ActionText to="/contact">Contact</Aui.ActionText>
        <Aui.ActionText to="/faq">FAQs</Aui.ActionText>
        <Aui.ActionText to="/attributions">With Thanks To</Aui.ActionText>
        <Aui.ActionText to="/terms">Terms of Use</Aui.ActionText>
        <Aui.ActionText to="/privacy">Privacy Policy</Aui.ActionText>
      </FooterContentContainer>

      <div style={{ gridArea: 'donate' }}>
        <Aui.Header center size={5}>Help Keep Awoken Bible Alive</Aui.Header>
        <p>
          Hi! Awoken Bible is currently a passion project by just one person. I believe the Bible should be freely accessible to everyone - but unfortunately web hosting costs money. Assistance in keeping this project alive would be greatly appreciated <Icon name="smile outline"/>
        </p>

        <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=89ZZ3VETHCFME&source=url">
          <Button primary><Icon name='paypal'/>Donate Today</Button>
        </a>
        <Aui.ActionText to='/donate'><Button>More Infomation</Button></Aui.ActionText>
      </div>

    </FooterContainer>
  );
}
