
import React           from 'react';
import styled          from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Icon  }       from 'semantic-ui-react';

import * as Aui              from '../design';

import NavSearch    from './nav-search';

// Width at which we switch from single horizontal bar to
// multiple lines
const MIN_WIDTH = '900px';

const NavBar = styled.nav`
background-color: white;
border-bottom: 1px solid rgba(0,0,0,0.5);
display: grid;
grid-template-columns: repeat(3, 1fr);

@media ( max-width: ${MIN_WIDTH} ) {
  grid-template-columns: 1fr;
}
`;

const NavButtonContainer = styled.div`
margin: 0px;
padding: 0px;
text-align: ${props => props.align};

@media ( max-width: ${MIN_WIDTH} ) {
  padding: 0em 1em;
  display: flex;
  justify-content: center;
}
`;

const NavItem = (props) => <Aui.TabButton {...props} height='calc(4rem + 5px)'/>;

export default function TopNav(){

  const location = useLocation();

  // let is_logged_in = true;
  //
  // let account_nav = is_logged_in ? <NavLoggedIn/> : <NavLogin/>;

  let nav_targets = [
    { path: '/',        text: '',        icon: 'home', exact: true },
    { path: '/read',    text: 'Read',    icon: 'book' },
    { path: '/explore', text: 'Explore', icon: 'compass outline' },
  ];

  let active_nav_target = '';
  for(let x of nav_targets){
    if((x.exact && location.pathname === x.path) ||
       (!x.exact && (location.pathname.startsWith(x.path) &&
                     location.pathname.length > active_nav_target.length
                    )
       )
      ){
      active_nav_target = x.path;
    }
  }

  let nav_items = nav_targets.map((x) => (
    <NavItem
      key={x.path} to={x.path} active={x.path === active_nav_target ? 1 : undefined}
    >
      <Icon name={x.icon}/>{x.text}
    </NavItem>
  ));;

  return (
    <NavBar>

      <NavButtonContainer align='left'>
        { nav_items }
      </NavButtonContainer>

      <NavButtonContainer style={{marginTop: '0.6em'}}>
        <NavSearch/>
      </NavButtonContainer>
    </NavBar>
  );

  //<NavButtonContainer align='right'>
  //  { account_nav }
  //</NavButtonContainer>
}
