/**
 * Renders text which has an associated action when clicked
 *
 * Will intelligently render a <a> tag to external sites, react router dom link
 * to internal pages, or a span with associated CSS for onClick-able text
 */

import React    from 'react';
import styled   from 'styled-components';
import { Link } from 'react-router-dom';

function mkAction(kind){
  return styled(kind)`
size: ${props => props.size ? props.size : 'inherit'};
cursor: pointer;
color: ${props => props.dull ? props.theme.color.dull : props.theme.color.blue};
transition: color ${props => props.theme.transition.quick};
body.has_hover &:hover {
  color: ${props => props.theme.color.pink};
}
`;
}

const ActionSpan = mkAction('span');
const ActionLink = mkAction(Link);
const ActionHref = mkAction('a');

function ActionText(props){
  const { to, className, style, dull, onClick } = props;

  const child_props = {
    style, className, dull
  };

  if(to){
    if(to.indexOf('://') >= 0){
      // Then its an external link, use standard <a> tag
      return (
        <ActionHref {...child_props} href={to}>{props.children}</ActionHref>
      );
    } else {
      return (
        <ActionLink {...child_props} to={to}>{props.children}</ActionLink>
      );
    }
  } else {
    return (
      <ActionSpan {...child_props} onClick={onClick}>{props.children}</ActionSpan>
    );
  }
}

export default ActionText;
export { ActionText };
