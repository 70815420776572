/**
 * Implements scroll to top of page on nav link click
 * See https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
 */

import React           from 'react';
import { useLocation } from 'react-router-dom';

// Always scroll to top when url changes
export function ScrollToTop(){

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);

  return null;
}

// Scroll to top when a component is mounted, eg, can have multiple tabs with different urls
// in middle of page, and only scroll to top when the tap container is remounted, rather than
// an individual tab
export function ScrollToTopOnMount(){
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);

  return null;
}
