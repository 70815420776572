import React, { useRef, useState, useEffect } from 'react';
import styled                                 from 'styled-components';

const Container = styled.span`
min-width: 10em;
min-height: 1em;
display: inline-block;
`;

const Hidden = styled.span`
position     : absolute;
height       : 0;
overflow     : hidden;
white-space  : pre;
font-size    : inherit;
font-familty : inherit;
`;

const EditBox = styled.input`
padding     : 0px;
margin      : 0px;
border      : 0px;
outline     : 0px;
font-size   : inherit;
font-family : inherit;
&:focus: {
  border  : 0px;
  outline : 0px;
}
color: ${props => props.is_error ? props.theme.color.error : 'inherit'};
background-color: ${props => props.theme.color.input_background};
`;

const Prediction = styled.span`
padding     : 0px;
margin      : 0px;
color       : ${props => props.theme.color.faint},
white-space : pre; // prevent leading whitespace in prediction being collapsed
display     : ${(props) => props.has_focus ? 'inline-block' : 'hidden'}
`;

export const PredictiveInput = React.forwardRef((props, ref) => {
  let {
    /**
     * string - represents initial value of text field
     * Can alternatively pass in a value and setValue function if you want the
     * useState value to live in a parent component rather than this
     */
    initial_value,

    /** string[] - Set of strings that text field can autocomplete to */
    predictions,

    /**
     * boolean - if true then predictions will be suggested regardless of the
     * case
     */
    ignore_case,

    /** string - value shown when textfield is empty */
    placeholder,

    /**
     * boolean - if set a space will be appended to input after user accepts a
     * prediction, good if you expect them to continue to type
     */
    add_space_on_complete,

    /**
     * boolean - if set then input text will be rendered in red
     */
    is_error,

    /** (value: string) => void - Function called when user accepts a prediction */
    onComplete = () => {},

    /**
     * (value: string) => void - Function called after each keystroke /
     * value change with the new value
     */
    onInput = () => {},

    /**
     * (event) => void - Function called whenever a key is pressed
     *
     * Note that internally, onKeyDown is processed in order to intercept
     * the tab character used to accept the autocomplete prediction.
     * The passed onKeyDown will be called first, and if preventDefault is called
     * on the event object then tab processing will not be performed
     */
    onKeyDown = () => {},

    /**
     * (event) => void - Function called whenever a key is released
     */
    onKeyUp = () => {},

    /**
     * (value: string) => void - Function called when the input field
     * looses focus, implying the user has finished entering a value
     */
    onBlur,

    /**
     * () => void - Called when the input field gains focus
     */
    onFocus,
  } = props;

  // We have to useState outside any conditional - but we can just ignore it if
  // the parent has passed in a value/setValue
  let [ internal_value, setInternalValue ] = useState(initial_value || "");
  let value, setValue;
  if(props.value != null && props.setValue != null){
    if(props.initial_value !== undefined){
      throw new Error("Cannot specify both inital_value and value/setValue");
    }
    value    = props.value;
    setValue = props.setValue;
  } else if (props.value == null || props.setValue == null){
    value    = internal_value;
    setValue = setInternalValue;
  } else {
    throw new Error("Cannot specify one of value/setValue, must use both or neither");
  }

  let [ current_prediction, setPrediction ] = useState("");
  let [ has_focus, setHasFocus            ] = useState(false);

  let elm_edit = useRef();
  if(ref){
    elm_edit = ref;
  }
  const elm_prediction  = useRef();
  const elm_hidden      = useRef();

  useEffect(() => {
    elm_hidden.current.textContent = value;
    elm_edit.current.style.width = (elm_hidden.current.offsetWidth + 1) + "px";
  }, [ value ]);

  // Update the predictions whenever the text value changes, either due to input, or
  // programatially if this is being used as a controlled component
  React.useEffect(() => {
    let filterFunc;
    if(ignore_case){
      filterFunc = (x) => x.toLowerCase().startsWith(value.toLowerCase());
    } else {
      filterFunc = (x) => x.startsWith(value);
    }

    let preds = predictions.filter(filterFunc);
    if(add_space_on_complete){
      preds = preds.map(x => `${x} `);
    }
    if(preds.length === 1){
      setPrediction(preds[0].substring(value.length));
    } else {
      setPrediction('');
    }
  }, [ value, setPrediction, predictions ]);

  function _onInput(e){
    let new_value = e.target.value;
    setValue(new_value);
    onInput(new_value);
  }

  function _onKeyDown(e){
    onKeyDown(e);
    if(!e.defaultPrevented && e.keyCode === 9){
      if(current_prediction !== ''){
        e.preventDefault(); // prevent loss of focus

        let new_value = value + current_prediction;
        setValue(new_value);
        setPrediction('');
        onComplete(new_value);
      }
      return;
    }
  }

	return (
    <Container
      onClick   = {e => elm_edit.current.focus()}
      className = {props.className}
      style     = {props.style}
    >
      <Hidden ref={elm_hidden}></Hidden>
      <EditBox is_error={is_error}
               ref={elm_edit}
               onKeyDown={_onKeyDown} onKeyUp={onKeyUp} onChange={_onInput}
               onFocus={(e) => { setHasFocus(true ); if(onFocus){ onFocus(e); } } }
               onBlur= {(e) => { setHasFocus(false); if(onBlur ){ onBlur (e); } } }
               value={value}
               placeholder={placeholder}
               size={props.size}
      />
      <Prediction has_focus= { has_focus }
                  ref      = { elm_prediction }
      >
        { current_prediction }
      </Prediction>
    </Container>
  );
});

export default PredictiveInput;
