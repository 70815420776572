// Static resources
import 'semantic-ui-less/semantic.less';
import './index.css';

import React                    from 'react';
import ReactDOM                 from 'react-dom';
import { BrowserRouter,
         useLocation }          from 'react-router-dom';
import { Helmet }               from 'react-helmet';
import ReactGA                  from 'react-ga';
import { ThemeProvider }        from 'styled-components';

import * as UseHttp        from 'use-http';
import * as serviceWorker  from './serviceWorker';

import * as BibleText   from './data/BibleText';
import * as EntityGraph from './data/EntityGraph';
import * as VrefKnow    from './data/VrefKnowledge';

import AppContent      from './AppContent';

import { Theme } from './ui/design/Theme';

{
  let testMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  console.log("Initializing google analytics, test mode: " + testMode);
  ReactGA.initialize('UA-167911642-1', {
    testMode,
  });
}

// Automatically add "has_hover" class to body element when a mouse is moved,
// but remove when a touch event occurs. This allows us to prevent css hover
// effects apply after a element is tapped on touchscreen and virtual cursor
// then does not move again.
// See: https://stackoverflow.com/a/30303898/1313573
function watchForHover() {
  let lastTouchTime = 0;

  function enableHover() {
    if (new Date() - lastTouchTime < 500) return;
    document.body.classList.add('has_hover');
  }

  function disableHover() {
    document.body.classList.remove('has_hover');
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date();
  }

  document.addEventListener('touchstart', updateLastTouchTime, true);
  document.addEventListener('touchstart', disableHover, true);
  document.addEventListener('mousemove', enableHover, true);

  enableHover();
}
watchForHover();


function ProviderStack(props){
  return (
    <BrowserRouter>
      <UseHttp.Provider>
        <ThemeProvider theme={Theme}>
          <BibleText.HOC>
            <EntityGraph.HOC>
              <VrefKnow.HOC>
                { props.children }
              </VrefKnow.HOC>
            </EntityGraph.HOC>
          </BibleText.HOC>
        </ThemeProvider>
      </UseHttp.Provider>
    </BrowserRouter>
  );
}

function App(){


  return (
    <ProviderStack>
			<Helmet>
        <title>Awoken Bible</title>
      </Helmet>

      <GaWrapper/>
		</ProviderStack>
	);
}

function GaWrapper(){
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
  }, [ location ]);

  return <AppContent/>;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
