/**
 * Defines the theme object which we provide to all styled components
 */

const PALLETE = {
  blue       : '#4000FF',
  pink       : '#FF00BF',
  orange     : '#FFA000',

  cyan       : '#0496FF',
  green      : '#7FD8BE',
  red        : '#F71735',
  yellow     : '#EFCA08',

  white      : '#FFFFFF',
  light_grey : '#839095',
  mid_grey   : '#535C5F',
  dark_grey  : '#2F3537',
  black      : '#14181C',
};

const Theme = {
  /**
   * Re-usable colors to use in the GUI
   */
  color: {
    ...PALLETE,

    primary    : PALLETE.blue,
    secondary  : PALLETE.pink,

    primary_text   : PALLETE.white, // text which shows up on primary/secondary color
    secondary_text : PALLETE.white, // text which shows up on primary/secondary color

    input_background: '#F3f3F3',

    error      : PALLETE.red,

    focused    : PALLETE.blue,    // input borders

    background : PALLETE.white,
    foreground : PALLETE.black,
    dull       : PALLETE.mid_grey,
    faint      : 'rgba(34, 36, 38, 0.15)', //PALLETE.light_grey,
  },

  /**
   * In order to make sure multiple animations all progress at a consistent rate we
   * define various categories of transition to use
   */
  transition: {
    quick: '0.15s',
  },

  // Curviture on input borders
  input_radius: '3px',
  shadow_spread: '3px',

  // How wide to render long pieces of text (too much makes it hard to follow since lines
  // are too long)

  text_width: '50em',
};

export default Theme;
export { Theme };
