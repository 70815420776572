/**
 * :TODO: eventually we want to get rid of semantic ui since its not maintained, and
 * means we have to duplicate theme data between us and them
 * We need to implement this using base font-awesome when we do.
 */


import { Icon } from 'semantic-ui-react';
export default Icon;

export { Icon } from 'semantic-ui-react';
