/**
 * Outer wrapper around a page's content
 */

import React  from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { ScrollToTop } from './ScrollToTop';

const OuterContainer = styled.div`
margin: 0px auto;

flex-grow: 1;

padding: ${props => props.no_pad ? '0px' : '2em'};

@media(max-width: ${props => props.mobile_width || '0px'}){
  padding: ${props => props.no_pad ? '0px' : '2em'} 0px;
}

width: 100%;
max-width: ${props => props.max_width ? props.max_width : '999999px'};

text-align: ${props => props.text_align ? props.text_align : 'inherit'};

display: flex;
flex-direction: column;
justify-content: ${props => props.vcenter ? 'space-around' : 'start'};
`;

/**
 * Main page container
 * - max_width        - CSS value for maximum width, if specified then page content will be centered
 * - vcenter          - If truey, and content is not as tall as page, then content will be vertically centered
 * - preserve_scroll  - If set then scroll position will be preserved when url changes but this component stays mounted
 * - text_align       - CSS text align to apply
 * - title            - Page title (IE: <title> tag displayed in browser tab bar)
 * - no_pad           - No padding is added between edge of screen and child components
 * - mobile_width     - If size is smaller or equal to this, this no padding is added at screen edges
 * - no_index         - If set appropriate meta tags will be added to prevent search engines from indexing the page
 * - meta_description - <meta name="description"> content for page - helps SEO
 * - canonical        - Canonical URL to add to <head> of page - helps SEO if multiple URLs for same content
 */
export default function Page(props){

  return (
    <OuterContainer style={props.style}
                    max_width={props.max_width}
                    vcenter={props.vcenter}
                    text_align={props.text_align}
                    no_pad={props.no_pad}
                    mobile_width={props.mobile_width}
    >

      <Helmet>
        <title>{props.title ? `${props.title} | Awoken Bible` : 'Awoken Bible' }</title>
        { props.no_index ? <meta name="robots" content="noindex"/> : null }
        { props.meta_description ? <meta name="description" content={props.meta_description}/> : null }
        { props.canonical ? <link rel="canonical" href={props.canonical}/> : null }
      </Helmet>

      { props.preserve_scroll ? null : <ScrollToTop/> }

      { props.children }
    </OuterContainer>
  );
}

export { Page };
