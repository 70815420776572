/**
 * Displays an alert message to user
 */

import React  from 'react';
import styled from 'styled-components';

import Card from './Card';

// Maps the props "kind" to a list of theme color names
const COLOR_MAP = {
  'error'    : { background: 'error', foreground: 'white' },
  'negative' : { background: 'error', foreground: 'white' },

  'positive' : { background: 'green', foreground: 'black' },
  'success'  : { background: 'green', foreground: 'black' },

  'warning'  : { background: 'yellow', foreground: 'black' },
};

const Container = styled(Card)`
text-align: center;
background-color: ${props => props.theme.color[COLOR_MAP[props.kind].background]};
color: ${props => props.theme.color[COLOR_MAP[props.kind].foreground]};
font-weight: bold;

ul {
  list-style-type: none;
}
`;

export default function Alert(props){
  return (
    <Container kind={props.kind}>
      { props.children }
    </Container>
  );
}

export { Alert };
