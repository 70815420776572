/**
 * Data store for Biblical text data
 *
 * :TODO: This should have an associated service worker to automatically download the text data and
 * cache on the client side
 */


import React from 'react';
import AwokenRef from 'awoken-bible-reference';

const Context = React.createContext({ store: {} });

async function _getChapterData(version, book_id, chapter){
  return await fetch(
    `/api/text/${version.toLowerCase()}/${book_id.toLowerCase()}${chapter}`,
  );
}

async function _getVersionData(version, book_id, chapter){
  let res = await fetch(`/api/text/version`);

  return await res.json();
}

/**
 * HOC which provides the context to all subcomponents
 */
export function HOC(props){
  const [ store,        setStore       ] = React.useState({});
  const [ version_data, setVersionData ] = React.useState({});

  React.useEffect(() => {
    _getVersionData().then(setVersionData);
  }, []);

  // In memory set of fetches currently in progress, gets cleared each time we re-render
  // this component, which occurs when we recieve results from a fetch and call setStore
  //
  // We need this to prevent duplicate fetches however, since React likes to batch setState
  // calls together, and hence if multiple components are rendered which both useBibleText
  // on the same chapter then they would both start the fetch, as neither sees the setState
  // result from the other. Hence we use this object to store the state just long enough to
  // prevent duplicated fetches until the next batch of setState calls are processed by React
  let cur_fetches = {};

  function ensureVerserefsAvailable(version, verseref){
    let promises = [];

    for(let chpt of AwokenRef.iterateByChapter(verseref, true)){
      let book_id = chpt.start.book;
      let chapter = chpt.start.chapter;

      // Check if we should skip fetching this chapter
      let fetch_id = `${version}-${book_id}{chapter}`;
      if(cur_fetches[fetch_id]){
        continue;
      }
      if(store[version] &&
         store[version][book_id] &&
         store[version][book_id][chapter]
        ) {
        if(store[version][book_id][chapter].loading){
          continue;
        }
        if(!store[version][book_id][chapter].error){
          continue;
        }
      }

      // Mark this chapter as having a fetch in progress so no-one else duplicates the fetch
      cur_fetches[fetch_id] = true;
      setStore(s => {
        let new_s = { ...s };
        if(!new_s[version])         { new_s[version]          = {}; }
        if(!new_s[version][book_id]){ new_s[version][book_id] = {}; }
        new_s[version][book_id][chapter] = { loading: true };
        return new_s;
      });

      // Actually make the fetch...
      promises.push(
        _getChapterData(version, book_id, chapter)
          .then(async (res) => {
            if(res.status === 200){
              try {
                let data = await res.json();
                setStore(s => {
                  let new_s = { ...s };
                  new_s[version][book_id][chapter] = data;
                  return new_s;
                });
              } catch (e){
                console.error(e);
                setStore(s => {
                  let new_s = { ...s };
                  new_s[version][book_id][chapter] = { error: "Invalid response format" };
                  return new_s;
                });
              }
            } else {
              console.dir(res);
              setStore(s => {
                  let new_s = { ...s };
                  new_s[version][book_id][chapter] = { error: "Failed to fetch data" };
                  return new_s;
              });
            }
          })
      );
    }

    return Promise.all(promises);
  }

  const provided = {
    store,
    version_data,
    ensureVerserefsAvailable,
  };

  return (
    <Context.Provider value={provided}>
      { props.children }
    </Context.Provider>
  );
}

/**
 * Hook to be called by components that want to use full list of Bible versions
 */
//export function useBibleVersionList(){
//  let context = React.useContext(Context);
//
//  const result = React.useMemo(() => {
//    let result = [];
//    for(let id of Object.keys(context.version_data)){
//      result.push({ ...context.version_data[id], id });
//    }
//    return result;
//  }, [ context ]);
//
//  return result;
//}

/**
 * Returns map of version ids to version meta data
 */
export function useBibleVersionMap(){
  let context = React.useContext(Context);
  return context.version_data;
}

/**
 * Hook to get meta data about a given Bible version, or undef if the version
 * id is not valid
 */
export function useBibleVersion(version_id){
  let context = React.useContext(Context);
  return context.version_data[version_id];
}

/**
 * Hook to be called by components that want to use the text data
 */
export function useBibleText(verseref, version = 'WEB'){
  let context = React.useContext(Context);

  React.useEffect(() => {
    if(!verseref){ return; }
    context.ensureVerserefsAvailable(version, verseref);
  }, [ context, verseref, version ]);

  const [ block_data, any_loading ] = React.useMemo(() => {
    if(!verseref){
      return [ null, false ];
    }

    let any_loading = false;

    let result = AwokenRef.splitByChapter(verseref).map((v) => {
      let book_id = v.is_range ? v.start.book    : v.book;
      let chpt    = v.is_range ? v.start.chapter : v.chapter;
      if(!context.store[version] ||
         !context.store[version][book_id] ||
         !context.store[version][book_id][chpt] ||
         context.store[version][book_id][chpt].loading
        ){
        any_loading = true;
        return {
          verseref : v,
          loading  : true,
        };
      } else {
        any_loading = any_loading || context.store[version][book_id][chpt].loading;
        let data = context.store[version][book_id][chpt];
        let [ text, styling ] = _extractChapterSubsection(data, v);
        return {
          verseref : v,
          loading  : false,
          book     : data.book,
          chapter  : data.chapter,
          version  : data.version,
          text, styling,
        };
      }
    });

    return [ result, any_loading ];
  }, [ verseref, version, context.store ]);

  return {
    loading: any_loading,
    blocks: block_data,
  };
}

function _extractChapterSubsection({ text, styling }, verseref){
  let start_v, end_v;
  if(verseref.is_range){
    start_v = verseref.start.verse;
    end_v   = verseref.end.verse;
  } else {
    start_v = verseref.verse;
    end_v   = verseref.verse;
  }

  let start_idx = 0;
  let end_idx   = 0;

  let i = 0;
  // Only trim start if verse > 1 - this ensures we don't trim off a section title
  // before first verse. :TODO: make this more general, really we should keep all styling
  // elements from the end of the preceeding verse, to capture section titles
  if(start_v > 1){
    for(; i < styling.length; ++i){
      if(styling[i].kind !== 'v'){ continue; }

      if(styling[i].ref.is_range){
        if(styling[i].ref.start.verse <= start_v &&
           styling[i].ref.end.verse   >= start_v){
          start_idx = styling[i].min;
          break;
        }
      } else if(styling[i].ref.verse === start_v){
        start_idx = styling[i].min;
        break;
      }
    }
  }
  if(i > 0){ --i; }
  for(; i < styling.length; ++i){
    if(styling[i].kind !== 'v'){ continue; }

    if(styling[i].ref.is_range){
      if(styling[i].ref.start.verse <= end_v &&
         styling[i].ref.end.verse   >= end_v){
        end_idx = styling[i].max;
        break;
      }
    } else if(styling[i].ref.verse === end_v){
      end_idx = styling[i].max;
      break;
    }
  }

  let out_text = text.substring(start_idx, end_idx);
  let out_styling = [];

  for(let sty of styling){
    if(sty.max > start_idx && sty.min < end_idx){
      out_styling.push({
        ...sty,
        min: Math.max(sty.min - start_idx, 0),
        max: sty.max - start_idx,
      });
    }
  }

  return [ out_text, out_styling ];
}
