/**
 * Renders a cookie consent banner
 */

import React  from 'react';
import styled from 'styled-components';

import { Button }       from 'semantic-ui-react';
import * as Aui         from '../design';
import { useCookies }   from 'react-cookie';

const Container = styled.div`
position: fixed;
z-index: 999999;
bottom: 0px;
left: 0px;
right: 0px;
background-color: rgba(0,0,0,0.8);
color: ${props => props.theme.color.white};
padding: 1em;
box-shadow: 0px 0px 3px 5px ${props => props.theme.color.dull};

text-align: center;

display: ${props => props.hidden ? 'none' : 'block'};

a {
  color: ${props => props.theme.color.cyan} !important;
}
`;

const TopBar = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

export default function CookieConsent(){

  const [ cookies, setCookie ] = useCookies(['awoken_cookie_consent']);

  const [ consent_given, setConsentGiven ] = React.useState(cookies.awoken_cookie_consent);

  function giveConsent(){
    setConsentGiven(true);
    setCookie('awoken_cookie_consent', 1, { maxAge: 86400 * 365 });
  }

  return (
    <Container hidden={consent_given}>
      <TopBar>
        <Aui.Header>Cookie Usage</Aui.Header>
      </TopBar>

      <p>
        Awoken Bible uses cookies for a number of purposes, and we're required by law to ask for your permission. Cookies are small bits of infomation stored by the browser of your device, which we use to remeber infomation about you, such as which Bible translation you have selected. This means you can pick up right where you left off when you come back
      </p>

      <p>
        By continuing, to use the site, you agree to the usage described by our <Aui.ActionText to='/privacy'>Privacy Policy</Aui.ActionText>.
      </p>

      <Button fluid secondary onClick={giveConsent}>Allow All Cookies</Button>
    </Container>
  );
}
