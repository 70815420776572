/**
 * Thin wrapper around Collapse which generates clickable headings to expand/collapse sections
 */

import React from 'react';
import styled     from 'styled-components';

import ActionText from './ActionText';
import Collapse   from './Collapse';
import Icon       from './Icon';

const TitleContainer = styled.div`
font-size: 1.3em;
line-height: 1.3em;
`;

const SpinnerContainer = styled.span`
display: inline-block;
transform: ${props => props.expanded ? 'none' : 'rotate(-90deg)'};
transition: transform ${props => props.theme.transition.quick} ease;
`;

export default function Accordion({ children, title, expanded, setExpanded }){

  // We are optionally a controlled component, but default to using our own internal
  // state if none is supplied
  const [ my_expanded, setMyExpanded ] = React.useState(expanded || false);

  let real_state = my_expanded;
  if(expanded !== undefined){
    real_state = expanded;
  }

  function updateExpanded(){
    if(setExpanded){
      setExpanded(!expanded);
    } else {
      setMyExpanded(!my_expanded);
    }
  }

  let title_elm = title;
  if(typeof title === typeof 'a'){
    title_elm = <ActionText>{title}</ActionText>;
  }

  return (
    <div>
      <TitleContainer onClick={updateExpanded}>
        <SpinnerContainer expanded={real_state}>
          <Icon name='caret down'/>
        </SpinnerContainer>
        { title_elm }
      </TitleContainer>
      <Collapse expanded={real_state}>
        { children }
      </Collapse>
    </div>
  );
}

export { Accordion };
