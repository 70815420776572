/**
 * Flex box row container
 */

import styled     from 'styled-components';

const FlexRow = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
flex-wrap: wrap;
align-items: center;
`;

export { FlexRow };
export default FlexRow;
